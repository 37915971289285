<template>
  <ul class="menu-nav">

  </ul>
</template>

<script>
import ConfigService from "../../../core/services/config/ConfigService";
const configService = new ConfigService();
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      roles = roles.split(",");
      for (let t of roles) {
        t = t.trim();
        if (userRoles.includes(t)) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      }
      if (flag.includes(true)) {
        return true;
      } else return false;
    }
    },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    }
  }
};
</script>
