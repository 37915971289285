import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class OptionService {

    #api = null;

    constructor() {
        // this.#api = `${API_URL}user/class-timetable`;
        this.#api = `${API_URL}user/student/option`;
    }

    getOptionByKey(key){
        let url = `${this.#api}/${key}`;
        return apiService.get(url);
    }
}