<template>
  <div class="topbar-item">
    <div
            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
    >
      <span
              class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Welcome,
      </span>
      <span
              class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUser.first_name!=null ? currentUser.first_name : ''}}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="currentUser.photo" alt="Pic"  :src=" currentUser.image_path['thumb']" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.first_name !=null ? currentUser.first_name.charAt(0).toUpperCase() : '' }}
        </span>
      </span>
    </div>

    <div
            id="kt_quick_user"
            ref="kt_quick_user"
            class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
              class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
            Account settings
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
                href="#"
                class="btn btn-xs btn-icon btn-light btn-hover-primary"
                id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
              class="offcanvas-content pr-5 mr-n5 scroll"
              style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
                    class="symbol-label"
                    :src="currentUser.image_path !=null ? currentUser.image_path['thumb'] : ''"
                    alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
                    to="/user-profile"
                    class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ getFullName }}
            </router-link>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <i class="fas fa-envelope"></i>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
                  to="/user-profile"
                  @click.native="closeOffcanvas"
                  href="#"
                  class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                            src="media/svg/icons/Code/Settings4.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Account settings</div>
                <div class="text-muted">
                  Security & password
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
<!--          <router-link
                  to="/digital-id"
                  @click.native="closeOffcanvas"
                  href="#"
                  class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;
                    <inline-svg
                            src="media/svg/icons/Navigation/Settings-1.svg"
                    />
                    &lt;!&ndash;end::Svg Icon&ndash;&gt;
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Digital ID</div>
                <div class="text-muted">
                  Digital Id Card
                </div>
              </div>
            </div>
          </router-link>-->
          <router-link
                  to="/support"
                  @click.native="closeOffcanvas"
                  href="#"
                  class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                            src="media/svg/icons/Navigation/Settings-1.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Support</div>
                <div class="text-muted">
                  Support
                </div>
              </div>
            </div>
          </router-link>
          <router-link
                  to="/my-activity"
                  @click.native="closeOffcanvas"
                  href="#"
                  class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                            src="media/svg/icons/Code/Time-schedule.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Activities</div>
                <div class="text-muted">
                  Timeline
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->

        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #kt_quick_user {
    overflow: hidden;
  }
</style>

<script>
  import { mapGetters } from "vuex";
  import { LOGOUT } from "@/core/services/store/auth.module";
  import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
  import KTOffcanvas from "@/assets/js/components/offcanvas.js";

  export default {
    name: "KTQuickUser",
    data() {
      return {
        list: [
          {
            title: "Another purpose persuade",
            desc: "Due in 2 Days",
            alt: "+28%",
            svg: "media/svg/icons/Home/Library.svg",
            type: "warning"
          },
          {
            title: "Would be to people",
            desc: "Due in 2 Days",
            alt: "+50%",
            svg: "media/svg/icons/Communication/Write.svg",
            type: "success"
          },
          {
            title: "Purpose would be to persuade",
            desc: "Due in 2 Days",
            alt: "-27%",
            svg: "media/svg/icons/Communication/Group-chat.svg",
            type: "danger"
          },
          {
            title: "The best product",
            desc: "Due in 2 Days",
            alt: "+8%",
            svg: "media/svg/icons/General/Attachment2.svg",
            type: "info"
          }
        ]
      };
    },
    mounted() {
      // Init Quick User Panel
      KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    },
    methods: {
      onLogout() {
        this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
      },
      closeOffcanvas() {
        new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
      }
    },
    computed: {
      picture() {
        return process.env.BASE_URL + "media/users/default.jpg";
      },


      getFullName() {
        return (
                this.$store.getters.currentUser.first_name +
                " " +
                this.$store.getters.currentUser.last_name
        );
      },

      currentUser() {
        return this.$store.getters.currentUser;
      },
    }
  };
</script>
