var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(_vm.setting.online_class == '1')?_c('router-link',{attrs:{"to":{ name: 'online-class' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-chalkboard-teacher"}),_c('span',{staticClass:"menu-text"},[_vm._v("Online Classroom")])])])]}}],null,false,3973727976)}):_vm._e(),(_vm.setting.courses == '1')?_c('router-link',{attrs:{"to":{ name: 'students-courses' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-book"}),_c('span',{staticClass:"menu-text"},[_vm._v("Courses")])])])]}}],null,false,1131224935)}):_vm._e(),(_vm.setting.assignment == '1')?_c('router-link',{attrs:{"to":{ name: 'assignment' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon flaticon2-list-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Assignments")])])])]}}],null,false,2128727755)}):_vm._e(),(_vm.setting.timetable == '1')?_c('router-link',{attrs:{"to":{ name: 'students-routine' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-school"}),_c('span',{staticClass:"menu-text"},[_vm._v("Timetable")])])])]}}],null,false,1139455359)}):_vm._e(),(_vm.setting.attendance == '1')?_c('router-link',{attrs:{"to":{ name: 'general-attendance' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-school"}),_c('span',{staticClass:"menu-text"},[_vm._v("Check in/out")])])])]}}],null,false,3645867380)}):_vm._e(),(_vm.setting.attendance == '1')?_c('router-link',{attrs:{"to":{ name: 'gate-passes' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-school"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gate pass")])])])]}}],null,false,3252935506)}):_vm._e(),(_vm.setting.attendance == '1')?_c('router-link',{attrs:{"to":{ name: 'class-attendance' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon far fa-calendar-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Attendance")])])])]}}],null,false,80048927)}):_vm._e(),(_vm.setting.result == '1')?_c('router-link',{attrs:{"to":{ name: 'students-exam' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon far fa-clipboard"}),_c('span',{staticClass:"menu-text"},[_vm._v("Exams & results")])])])]}}],null,false,2225386419)}):_vm._e(),(_vm.setting.teacher_list == '1')?_c('router-link',{attrs:{"to":{ name: 'teachers' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-user-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v("Teachers List ")])])])]}}],null,false,444255832)}):_vm._e(),(_vm.setting.finance == '1')?_c('router-link',{attrs:{"to":{ name: 'finance' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-money-check-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v("Payment & invoices")])])])]}}],null,false,2557967377)}):_vm._e(),(_vm.setting.leaves == '1')?_c('router-link',{attrs:{"custom":"","to":{ name: 'leave' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-hand-holding-medical"}),_c('span',{staticClass:"menu-text"},[_vm._v("Leave request")])])])]}}],null,false,2530329229)}):_vm._e(),_c('router-link',{attrs:{"custom":"","to":{ name: 'user-group' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fa fa-layer-group"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.groupName))])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(_vm.generalRequestCategories.length > 0)?_vm._l((_vm.generalRequestCategories),function(category,index){return _c('router-link',{key:index,attrs:{"to":{name:'general-request', params: {type: category.value}}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas",class:category.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(category.name)+" ")])])])]}}],null,true)})}):_vm._e()],2)])]),(_vm.setting.finance == '1')?_c('router-link',{attrs:{"custom":"","to":{ name: 'invoice' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-file-invoice"}),_c('span',{staticClass:"menu-text"},[_vm._v("Invoice")])])])]}}],null,false,1316254443)}):_vm._e(),(_vm.setting.library == '1' && _vm.currentUser.enable_library)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Library")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.currentUser.enable_library)?_c('router-link',{attrs:{"custom":"","to":{ name: 'books' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-swatchbook"}),_c('span',{staticClass:"menu-text"},[_vm._v("Books")])])])]}}],null,false,1894055689)}):_vm._e(),(_vm.currentUser.enable_library)?_c('router-link',{attrs:{"custom":"","to":{ name: 'my-wishlist' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-heart"}),_c('span',{staticClass:"menu-text"},[_vm._v("Wishlist")])])])]}}],null,false,1992342829)}):_vm._e(),(_vm.currentUser.enable_library)?_c('router-link',{attrs:{"custom":"","to":{ name: 'my-issue' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-book-reader"}),_c('span',{staticClass:"menu-text"},[_vm._v("My books")])])])]}}],null,false,1588473711)}):_vm._e(),_c('router-link',{attrs:{"custom":"","to":{ name: 'parents-meet' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-calendar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Parents meet")])])])]}}])}),(_vm.currentUser.enable_library)?_c('router-link',{attrs:{"custom":"","to":{ name: 'resource' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon fas fa-shapes"}),_c('span',{staticClass:"menu-text"},[_vm._v("Resources")])])])]}}],null,false,225149573)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-bars"}),_c('span',{staticClass:"menu-text"},[_vm._v("General request")]),_c('i',{staticClass:"menu-arrow"})])
}]

export { render, staticRenderFns }