<template>
    <div>
        <div
                class="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"

        >
            <!--            <h4 class="d-flex flex-center rounded-top height-auto"  style="background: url('')">-->
            <!--                <span class=""> Notifications</span>-->

            <!--            </h4>-->
            <div class="d-flex flex-column pt-6 bgi-size-cover bgi-no-repeat rounded-top"
                 style="background: url('https://images.unsplash.com/photo-1556660418-fc293a5d848d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');">
                <h4 class="d-flex flex-center rounded-top"><span class="text-white">User Notifications</span><span
                        class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"> {{notifications.length}} new </span></h4>
            </div>

            <div class="ml-3 mr-2">

                <perfect-scrollbar
                        class="scroll pr-7 mr-n7"
                        style="max-height: 40vh; position: relative;"
                >
                    <v-skeleton-loader
                            v-if="is_loading"
                            v-bind="attrs"
                            type="list-item-avatar, list-item-avatar, list-item-avatar"
                    ></v-skeleton-loader>
                    <!--begin::Item-->
                    <template  v-if="!is_loading && notifications && notifications.length">
                        <template v-for="(item, i) in notifications">
                            <div :key="item.id" class="navi navi-hover scroll my-4 ps ps--active-y"
                                 style="max-height: 40vh; position: relative;">
                                <a :href="item.link ? item.link : ''" target="_blank" class="navi-item">
                                    <div class="navi-link">
                                        <v-avatar tile  v-if="item.image">
                                        <img
                                                :src="item.image_path['real']"
                                                alt="John"
                                        >
                                        </v-avatar>
                                        <v-avatar
                                                v-else
                                                tile
                                                color="blue"
                                        >
                                            <v-icon >
                                                mdi-bell
                                            </v-icon>
                                        </v-avatar>

                                        <div class="navi-text ml-2">
                                            <div class="font-weight-bold text-truncate">
                                                {{item.title}}
                                                <br>
                                                {{ item.description.substring(0, 50)+'...' }}</div>
                                            <div class="text-muted">{{item.notified_at | moment("from", "now")}}</div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <!--end::Item-->
                        </template>
                    </template>
                    <div v-if="!is_loading && notifications.length==0"  class="d-flex flex-column align-items-center  p-3">
                            <img width="50%" src="@/assets/media/images/no-data-found.png">
                        <h5 class="mt-10">No New Notification.</h5>
                    </div>

                    <div class="font-weight-bold mb-3 text-right pull-right" style="width: 100px"
                         v-if="!is_loading && notifications && notifications.length>0">
                        <router-link :to="{ name: 'notification'}"

                                     class="btn btn-outline-primary text-weight-bold"
                        >
                            View All
                        </router-link>

                    </div>
                </perfect-scrollbar>

            </div>

        </div>


    </div>
</template>

<style lang="scss">
    /* hide default vue-bootstrap tab links */
    .hide-tabs > div:not(.tab-content) {
        display: none;
    }
</style>

<script>
    import {API_URL} from '../../../../common/config'
    import NotificationService from "@/core/services/notification/NotificationService";

    const notificationService = new NotificationService();
    export default {
        name: "KTDropdownNotification",
        data() {
            return {
                tabIndex: 0,
                is_loading: false,
                attrs: false,
                notifications: []
            };
        },
        mounted() {
            // this.getNotifications()
        },
        created() {
            this.$bus.on('checkNotification', () => {
                this.getNotifications()
            })
        },
        methods: {
            getNotifications() {
                this.is_loading = true;
                notificationService.all().then((response) => {
                    this.notifications = response.data.data;
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                    this.is_loading = false;
                })
            },
            viewNotification(notification) {
                if (notification.status != 'pending') {
                    this.getNotifications();
                    window.open(`${API_URL}notification/view/${notification.id}`, "_blank");
                } else {
                    notification.is_viewed = true;
                    notificationService.update(notification.id, notification).then((res) => {
                        this.getNotifications();
                    }).catch((err) => {
                        this.getNotifications();
                    })
                }
            },
            setActiveTab(event) {
                const tab = event.target.closest('[role="tablist"]');
                const links = tab.querySelectorAll(".nav-link");
                // remove active tab links
                for (let i = 0; i < links.length; i++) {
                    links[i].classList.remove("active");
                }

                // set clicked tab index to bootstrap tab
                this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

                // set current active tab
                event.target.classList.add("active");
            }
        },
        computed: {
            backgroundImage() {
                return process.env.BASE_URL + "media/misc/bg-1.jpg";
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    };
</script>

<style scoped>
    .read {
        background-color: #f6f6f6;
    }
</style>
