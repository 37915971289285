<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-home"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.online_class == '1'"
      :to="{ name: 'online-class' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-chalkboard-teacher"></i>
          <span class="menu-text">Online Classroom</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="setting.courses == '1'"
      :to="{ name: 'students-courses' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-book"></i>
          <span class="menu-text">Courses</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.assignment == '1'"
      :to="{ name: 'assignment' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon flaticon2-list-1"></i>
          <span class="menu-text">Assignments</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.timetable == '1'"
      :to="{ name: 'students-routine' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-school"></i>
          <span class="menu-text">Timetable</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.attendance == '1'"
      :to="{ name: 'general-attendance' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-school"></i>
          <span class="menu-text">Check in/out</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.attendance == '1'"
      :to="{ name: 'gate-passes' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-school"></i>
          <span class="menu-text">Gate pass</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.attendance == '1'"
      :to="{ name: 'class-attendance' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon far fa-calendar-check"></i>
          <span class="menu-text">Attendance</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.result == '1'"
      :to="{ name: 'students-exam' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon far fa-clipboard"></i>
          <span class="menu-text">Exams & results</span>
        </a>
      </li>
    </router-link>
    <!--      <router-link-->

    <!--                :to="{name:'students-results'}"-->
    <!--                v-slot="{ href, navigate, isActive, isExactActive }"-->
    <!--        >-->
    <!--            <li-->
    <!--                    aria-haspopup="true"-->
    <!--                    data-menu-toggle="hover"-->
    <!--                    class="menu-item menu-item-submenu"-->
    <!--                    :class="[-->
    <!--                isActive && 'menu-item-active',-->
    <!--                isExactActive && 'menu-item-active'-->
    <!--              ]"-->
    <!--            >-->
    <!--                <a :href="href" class="menu-link">-->
    <!--                    <i class="menu-icon far fa-clipboard"></i>-->
    <!--                    <span class="menu-text">Result </span>-->
    <!--                </a>-->
    <!--            </li>-->
    <!--        </router-link>-->
    <router-link
      v-if="setting.teacher_list == '1'"
      :to="{ name: 'teachers' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-user-alt"></i>
          <span class="menu-text">Teachers List </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="setting.finance == '1'"
      :to="{ name: 'finance' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-money-check-alt"></i>
          <span class="menu-text">Payment & invoices</span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="setting.leaves == '1'"
      custom
      :to="{ name: 'leave' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-hand-holding-medical"></i>
          <span class="menu-text">Leave request</span>
        </a>
      </li>
    </router-link>

    <router-link
      custom
      :to="{ name: 'user-group' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fa fa-layer-group"><span></span></i>
          <span class="menu-text">{{groupName}}</span>
        </a>
      </li>
    </router-link>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-bars"></i>
        <span class="menu-text">General request</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <template v-if="generalRequestCategories.length > 0">
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                v-for="(category, index) in generalRequestCategories" :key="index"
                :to="{name:'general-request', params: {type: category.value}}"
            >
              <li
                  class="menu-item "
                  :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link">
                  <i class="menu-icon fas" v-bind:class="category.icon"></i>
                  <span class="menu-text">{{ category.name }} </span>
                </a>
              </li>
            </router-link>
          </template>

        </ul>
      </div>
    </li>

    <router-link
      v-if="setting.finance == '1'"
      custom
      :to="{ name: 'invoice' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-file-invoice"></i>
          <span class="menu-text">Invoice</span>
        </a>
      </li>
    </router-link>
    <li
      class="menu-section"
      v-if="setting.library == '1' && currentUser.enable_library"
    >
      <h4 class="menu-text">Library</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="currentUser.enable_library"
      custom
      :to="{ name: 'books' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-swatchbook"></i>
          <span class="menu-text">Books</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="currentUser.enable_library"
      custom
      :to="{ name: 'my-wishlist' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-heart"></i>
          <span class="menu-text">Wishlist</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="currentUser.enable_library"
      custom
      :to="{ name: 'my-issue' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-book-reader"></i>
          <span class="menu-text">My books</span>
        </a>
      </li>
    </router-link>

    <router-link
      custom
      :to="{ name: 'parents-meet' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-calendar"></i>
          <span class="menu-text">Parents meet</span>
        </a>
      </li>
    </router-link>

    <router-link
      custom
      v-if="currentUser.enable_library"
      :to="{ name: 'resource' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link">
          <i class="menu-icon fas fa-shapes"></i>
          <span class="menu-text">Resources</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>
<script>
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import ConfigService from "../../../core/services/config/ConfigService";
import OptionService from "@/core/services/option/OptionService";
import GeneralRequestCategoryService from "@/core/services/general-request/category/GeneralRequestCategoryService";
import UserGroupService from "@/core/services/user/group/UserGroupService";
import studentMixin from "@/mixins/StudentMixin";

const configService = new ConfigService();
const setting = new SiteSettingService();
const optionService = new OptionService();
const generalRequestCategory = new GeneralRequestCategoryService();
const userGroup = new UserGroupService();

export default {
  name: "KTMenu",
  mixins: [studentMixin],
  data() {
    return {
      uniqueId: "",
      setting: {},
      generalRequestCategories: []
    };
  },
  mounted() {
    this.getOption();
    this.getGeneralRequestCategory();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    getOption() {
      optionService.getOptionByKey("ORGANISATION_UNIQUE_ID").then((response) => {
        this.uniqueId = response.data;
        this.getOrganisation(this.uniqueId);
      });
    },
    getOrganisation(uniqueId) {
      setting.educationalOrganisation(uniqueId).then((response) => {
        let setting = response.data.education_organisations;
        this.setting = setting.mobile_features;
        this.$store.commit("SET_ORGANISATION_SETTING", this.setting);
      });
    },
    getGeneralRequestCategory() {
      generalRequestCategory.getAllRequestCategoryMenu().then((response) => {
        this.generalRequestCategories = response.data.data;
        let exists = this.generalRequestCategories.some(item => item.value === 'club_meeting');
        this.$store.dispatch('GET_IS_PRESIDENT', exists);

      }).catch(() => {})
    },

  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
  },
};
</script>
